// CSS
import { createPinia } from 'pinia';
import Quasar from 'quasar/src/vue-plugin.js';;
import { createApp, markRaw } from 'vue';
import { useUserStore } from '@/store/useUserStore';
import App from './App.vue';
import useAzure from './hooks/azure';
import quasarUserOptions from './quasar-user-options';
import '@/assets/style/scss/layermark.scss';
import router from './router';

const app = createApp(App);
app.use(router);

const pinia = createPinia();
pinia.use(({ store }) => {
	store.router = markRaw(router);
});
app.use(pinia);

const userStore = useUserStore();
const msalInstance = useAzure(createInstance);

userStore.msalInstance = msalInstance;

async function createInstance() {
	app.use(Quasar, quasarUserOptions);

	app.mount('#app');
}
