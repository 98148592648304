import script from "./SetupTemplateStep.vue?vue&type=script&setup=true&lang=ts"
export * from "./SetupTemplateStep.vue?vue&type=script&setup=true&lang=ts"

const __exports__ = script;

export default __exports__
import QCard from 'quasar/src/components/card/QCard.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QSkeleton from 'quasar/src/components/skeleton/QSkeleton.js';
import QEditor from 'quasar/src/components/editor/QEditor.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QCard,QInput,QIcon,QBtn,QSkeleton,QEditor});
