import { AxiosRequestConfig } from 'axios';

export default async (request: AxiosRequestConfig) => {
	if (request.headers) {
		request.headers['Authorization'] = 'Bearer ' + localStorage.getItem('azure-token');
		request.headers['Application'] = 'MMS';
	}

	return request;
};
