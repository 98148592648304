// @ts-ignore
import userService from '@/api/userService';
import { defineStore } from 'pinia';
import { PublicClientApplication } from '@azure/msal-browser';
import { AxiosError } from 'axios';

export const useUserStore = defineStore('user', {
	state: () => ({
		msalInstance: undefined as PublicClientApplication | undefined,
		userName: 'MMS USER' as String,
		isAuthenticatedUser: false,
	}),
	getters: {
		userName: (state) => state.msalInstance?.getAllAccounts()[0]?.idTokenClaims?.name ?? 'MMS USER',
	},
	actions: {
		async logout(isPermissionError?: boolean) {
			try {
				if (isPermissionError) {
					if (this.router.currentRoute.value.name !== 'auth-error') {
						await this.router.replace({ name: 'auth-error' });
					}
				} else {
					this.msalInstance
						?.handleRedirectPromise()
						.then(() => {
							this.msalInstance
								?.logoutRedirect()
								.then(() => {
									localStorage.removeItem('azure-token');
									window.location.reload();
								})
								.catch((error) => {
									console.error(error);
									window.location.reload();
								});
						})
						.catch((error) => {
							console.error(error);
						});
				}
			} catch (e) {
				console.error('Logout failed!');
				window.location.reload();
			}
		},

		async loginUser() {
			try {
				await userService.loginWithKeycloak();
				this.router.push({ name: 'home' });
			} catch (e: unknown) {
				if (e instanceof AxiosError && e.response?.status === 401) {
					return this.logout(true);
				}

				window.location.reload();
			}
		},
	},
});
