import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import DashboardView from '@/components/pages/DashboardPage.vue';
import CreateTemplatePage from '@/components/pages/CreateTemplatePage.vue';
import PermissionErrorPage from '@/components/pages/PermissionErrorPage.vue';

const routes: Array<RouteRecordRaw> = [
	{
		path: '/',
		name: 'home',
		component: DashboardView,
	},
	{
		path: '/create-template',
		name: 'create template',
		component: CreateTemplatePage,
	},
	{
		path: '/auth-error',
		name: 'auth-error',
		component: PermissionErrorPage,
	},
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
});

export default router;
