import { NotificationPerGroupResponse, NotificationPerGroupTemplate } from '@/types/api/response/notificationTypes';
import { NotificationChartData, NotificationDetail, UsageRateItemInterface } from '@/types/notifications';
import { interpolateColors } from '../color-generator';

export const countAllNotifications = (groups: Array<NotificationPerGroupResponse>): number => {
	return groups.reduce((acc, group) => acc + group.notificationCount, 0);
};

export const createUsageRateItems = (groups: Array<NotificationPerGroupResponse>, label: string): Array<UsageRateItemInterface> => {
	const usageRateItems: Array<UsageRateItemInterface> = [];
	const { detailItems, colors } = createDetailItems(groups, countAllNotifications(groups));
	/**
	 * Create usage rate items and chart for the groups
	 */
	usageRateItems.push({
		title: label,
		chartData: createChartDataForDetailItems(detailItems, colors),
		detailItems: addTotalForDetailItems(detailItems),
	});
	/**
	 * Create usage rate items and chart for the templates
	 */
	groups.forEach((group) => {
		const { detailItems, colors } = createDetailItems(group.templates, group.notificationCount);

		usageRateItems.push({
			title: `${group.name} - Templates`,
			chartData: createChartDataForDetailItems(detailItems, colors),
			detailItems: addTotalForDetailItems(detailItems),
		});
	});

	return usageRateItems;
};

const isTotalNotificationNonZero = (detailItems: Array<NotificationDetail>) => {
	const total = detailItems.reduce((acc, item) => acc + item.count, 0);

	return total > 0;
};

const getCountAndPercentage = (count: number, total: number) => {
	if (count === 0) {
		return [0, 0];
	}

	return [count, Math.floor((count / total) * 100)];
};

const createChartDataForDetailItems = (detailItems: Array<NotificationDetail>, colors: Array<string>) => {
	/**
	 * If there are no notifications, return an empty chart
	 */
	if (!isTotalNotificationNonZero(detailItems)) {
		return {
			labels: [],
			datasets: [{ data: [1], backgroundColor: ['#f1f1f1'] }],
		};
	}

	const chartData: NotificationChartData = {
		labels: [],
		datasets: [{ data: [], backgroundColor: colors }],
	};

	detailItems.forEach((item) => {
		chartData.labels.push(item.label);
		chartData.datasets[0].data.push(item.percentage);
	});

	return chartData;
};

const addTotalForDetailItems = (detailItems: Array<NotificationDetail>) => {
	detailItems.push({
		label: 'Total',
		count: detailItems.reduce((acc, item) => acc + item.count, 0),
		percentage: 100,
		bgColor: '#ECF4F8',
		color: 'black',
	});

	return detailItems;
};

const createDetailItems = (items: Array<NotificationPerGroupResponse | NotificationPerGroupTemplate>, totalCount: number) => {
	let colorIndex = 0;
	const colors = interpolateColors(items.length);
	const sortedByNotification = items.sort((a, b) => b.notificationCount - a.notificationCount);

	const detailItems: Array<NotificationDetail> = sortedByNotification.map((item) => {
		const [count, percentage] = getCountAndPercentage(item.notificationCount, totalCount);

		return {
			label: item.name,
			count: count,
			percentage: percentage,
			bgColor: colors[colorIndex++],
			// First half of the colors are dark, so we need to change the text color to white
			color: colorIndex > colors.length / 2 ? 'black' : 'white',
		};
	});

	return { detailItems, colors };
};
