import { defineStore } from 'pinia';
import templatesService from '@/api/templatesService';
import { FetchVariablesParams } from '@/types/api/params/fetchVariablesParams';
import { CreateVariableRequest } from '@/types/api/request/createTemplateTypes';
import { Variable } from '@/types/template/variable';
import { notifyApiError } from '@/utils/notifiers';
import { AxiosError } from 'axios';

export const useVariableStore = defineStore({
	id: 'templatevariables',
	state: () => ({
		variables: Array<Variable>(),
		loading: false,
	}),

	actions: {
		async fetchVariables(params?: FetchVariablesParams): Promise<void> {
			this.loading = true;

			try {
				const response = await templatesService.getVariables(params);
				this.variables = response.data.data as Array<Variable>;
			} catch (e) {
				notifyApiError(e as AxiosError);
				throw e;
			} finally {
				this.loading = false;
			}
		},

		async createVariable(payload: CreateVariableRequest): Promise<void> {
			this.loading = true;

			try {
				await templatesService.createVariable(payload);
				await this.fetchVariables({ size: 2000 });
			} catch (e) {
				notifyApiError(e as AxiosError);
				throw e;
			} finally {
				this.loading = false;
			}
		},

		async deleteVariable(id: number): Promise<void> {
			this.loading = true;

			try {
				await templatesService.deleteVariable(id);
				await this.fetchVariables({ size: 2000 });
			} catch (e) {
				notifyApiError(e as AxiosError);
				throw e;
			} finally {
				this.loading = false;
			}
		},
	},
});
