import moment from 'moment';
import { defineStore } from 'pinia';
import notificationsService from '@/api/notificationsService';
import { NotificationCountFilterParam, NotificationPerGroupFilterParam } from '@/types/api/params/notificationDateFilter';
import { CategoryNotificationCountResponse, NotificationPerGroupResponse } from '@/types/api/response/notificationTypes';
import { Maybe } from '@/types/coretypes';
import { CategoryNotificationCount, NotificationCountByDate, TotalNotificationCount } from '@/types/notifications';
import { notificationCountByDateTypes } from '@/constants/notificationCountByDateTypes';
import { notifyApiError } from '@/utils/notifiers';
import { AxiosError } from 'axios';

export const useNotificationsStore = defineStore({
	id: 'notifications',
	state: () => ({
		notificationsPerGroup: Array<NotificationPerGroupResponse>(),
		notificationsRate: undefined as Maybe<CategoryNotificationCount>,
		notificationCountByDate: Array<NotificationCountByDate>(),
		totalNotificationCount: undefined as Maybe<TotalNotificationCount>,
		fetchingNotificationsPerGroup: false,
		fetchingNotificationsRate: false,
	}),
	actions: {
		async fetchNotificationsPerGroup(filter?: Maybe<NotificationPerGroupFilterParam>): Promise<void> {
			this.fetchingNotificationsPerGroup = true;

			try {
				const response = await notificationsService.getNotificationsPerGroup(filter);
				this.notificationsPerGroup = response.data.data as Array<NotificationPerGroupResponse>;
			} catch (e) {
				notifyApiError(e as AxiosError);
				throw e;
			} finally {
				this.fetchingNotificationsPerGroup = false;
			}
		},

		async fetchTotalCountAndRate(): Promise<void> {
			this.fetchingNotificationsRate = true;

			try {
				await this.fetchTotalNotificationCount();
				await this.fetchNotificationCountsByDate();
			} catch (e: unknown) {
				notifyApiError(e as AxiosError);
				throw e;
			} finally {
				this.fetchingNotificationsRate = false;
			}
		},

		async fetchTotalNotificationCount(): Promise<void> {
			try {
				const response = await notificationsService.getNotificationCountsByDate();
				const counts = response.data.data as Array<CategoryNotificationCountResponse>;
				this.totalNotificationCount = {
					emailCount: counts.find((x) => x.type === 'EMAIL')?.notificationCount || 0,
					smsCount: counts.find((x) => x.type === 'SMS')?.notificationCount || 0,
				};
			} catch (e) {
				notifyApiError(e as AxiosError);
				throw e;
			}
		},

		async fetchNotificationCountsByDate(): Promise<void> {
			const generateNotificationDateFilters = (): Array<NotificationCountFilterParam> => {
				return notificationCountByDateTypes.map((item): NotificationCountFilterParam => {
					return {
						fromDateTime: moment()
							.subtract(item.duration, item.durationType as moment.unitOfTime.DurationConstructor)
							.format(),
						toDateTime: moment().format(),
					};
				});
			};

			const promises = generateNotificationDateFilters().map((filter) => {
				return notificationsService.getNotificationCountsByDate(filter);
			});

			const sumEmailAndSmsCounts = (counts: Array<CategoryNotificationCountResponse>) => {
				const emailCount = counts.find((x) => x.type === 'EMAIL')?.notificationCount || 0;
				const smsCount = counts.find((x) => x.type === 'SMS')?.notificationCount || 0;

				return emailCount + smsCount;
			};

			try {
				const responses = await Promise.all(promises);
				const counts = responses.map((response) => response.data.data as Array<CategoryNotificationCountResponse>);
				this.notificationCountByDate = counts.map((count, index) => {
					return { ...notificationCountByDateTypes[index], count: sumEmailAndSmsCounts(count) };
				});
			} catch (e) {
				notifyApiError(e as AxiosError);
				throw e;
			}
		},
	},
});
