import { useUserStore } from '@/store/useUserStore';
import { BrowserAuthError, InteractionRequiredAuthError, PublicClientApplication } from '@azure/msal-browser';

export default function useAzure(callBackFunction: () => void) {
	const msalConfig = {
		auth: {
			clientId: process.env.VUE_APP_CLIENT_ID,
			authority: `https://login.microsoftonline.com/${process.env.VUE_APP_TENANT_ID}`,
			navigateToLoginRequestUrl: false,
			redirectUri: process.env.VUE_APP_REDIRECT_URI,
		},
		cache: {
			cacheLocation: 'localStorage',
		},
	};

	const msalInstance = new PublicClientApplication(msalConfig);

	function setupTokenRefreshInterval() {
		setInterval(() => {
			const idTokenRequest = setIdTokenRequest();
			msalInstance.acquireTokenSilent(idTokenRequest).then(function (idTokenResponse) {
				setTokenValue(idTokenResponse.idToken);
			});
		}, 60 * 1000);
	}

	loginProcess();

	function setIdTokenRequest() {
		const myAccounts = msalInstance.getAllAccounts();
		const account = myAccounts[0];

		return {
			scopes: ['user.read'],
			account: account,
			forceRefresh: true,
		};
	}

	async function loginProcess() {
		const idTokenRequest = setIdTokenRequest();
		await msalInstance
			.acquireTokenSilent(idTokenRequest)
			.then(async function (idTokenResponse) {
				setupTokenRefreshInterval();
				await prepareApp(idTokenResponse);
			})
			.catch(function (error) {
				if (error instanceof InteractionRequiredAuthError) {
					msalInstance
						.acquireTokenPopup(idTokenRequest)
						.then(async function (idTokenResponse) {
							setupTokenRefreshInterval();
							await prepareApp(idTokenResponse);
						})
						.catch(function (error) {
							window.location.reload();
							console.error(error);
						});
				} else if (error instanceof BrowserAuthError && error.errorCode == 'no_account_error') {
					msalInstance
						.handleRedirectPromise()
						.then(() => {
							msalInstance
								.loginRedirect()
								.then(() => {
									loginProcess();
								})
								.catch((error) => {
									console.error(error);
									loginProcess();
								});
						})
						.catch((error) => {
							console.error(error);
						});
				}

				console.error(error);
			});
	}

	function setTokenValue(idToken: any) {
		localStorage.setItem('azure-token', idToken);
	}

	async function prepareApp(idTokenResponse: any) {
		const { loginUser } = useUserStore();
		setTokenValue(idTokenResponse.idToken);

		await loginUser();

		callBackFunction();
	}

	return msalInstance;
}
