import { AxiosError } from 'axios';
import { defineStore } from 'pinia';
import templatesService from '@/api/templatesService';
import { CreateTemplateRequest } from '@/types/api/request/createTemplateTypes';
import { Template } from '@/types/template/template';
import { notifyApiError } from '@/utils/notifiers';

export const useTemplateStore = defineStore({
	id: 'template_store',
	state: () => ({
		templates: Array<Template>(),
		loading: false,
		deleting: false,
	}),
	actions: {
		async fetchTemplates(): Promise<void> {
			this.loading = true;

			try {
				const response = await templatesService.getTemplates();
				this.templates = response.data.data as Array<Template>;
			} catch (e: unknown) {
				notifyApiError(e as AxiosError);
				throw e;
			} finally {
				this.loading = false;
			}
		},

		async createTemplate(template: CreateTemplateRequest): Promise<void> {
			this.loading = true;

			try {
				await templatesService.createTemplate(template);
				await this.fetchTemplates();
			} catch (e: unknown) {
				notifyApiError(e as AxiosError);
				throw e;
			} finally {
				this.loading = false;
			}
		},

		async updateTemplate(template: CreateTemplateRequest): Promise<void> {
			this.loading = true;

			try {
				await templatesService.updateTemplate(template);
				await this.fetchTemplates();
			} catch (e: unknown) {
				notifyApiError(e as AxiosError);
				throw e;
			} finally {
				this.loading = false;
			}
		},

		async deleteTemplate(id: number): Promise<void> {
			this.deleting = true;

			try {
				await templatesService.deleteTemplate(id);
				this.deleting = false;
				await this.fetchTemplates();
			} catch (e: unknown) {
				notifyApiError(e as AxiosError);
				throw e;
			} finally {
				this.deleting = false;
			}
		},
	},
});
