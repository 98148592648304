import { defineStore } from 'pinia';
import templatesService from '@/api/templatesService';
import { CreateTemplateGroupRequest } from '@/types/api/request/createTemplateTypes';
import { TemplateGroup } from '@/types/template/templateGroup';
import { notifyApiError } from '@/utils/notifiers';
import { AxiosError } from 'axios';

export const useGroupsStore = defineStore({
	id: 'templategroups',
	state: () => ({
		groups: Array<TemplateGroup>(),
		loading: false,
	}),
	actions: {
		async fetchGroups(): Promise<void> {
			this.loading = true;

			try {
				const response = await templatesService.getTemplateGroups();
				this.groups = response.data.data as Array<TemplateGroup>;
			} catch (e) {
				notifyApiError(e as AxiosError);
				throw e;
			} finally {
				this.loading = false;
			}
		},

		async createGroup(group: CreateTemplateGroupRequest): Promise<number> {
			this.loading = true;

			try {
				let response;

				if (group.id) {
					response = await templatesService.updateGroup(group);
				} else {
					response = await templatesService.createGroup(group);
				}

				await this.fetchGroups();

				return response.data.data.id;
			} catch (e) {
				notifyApiError(e as AxiosError);
				throw e;
			} finally {
				this.loading = false;
			}
		},

		async deleteGroup(id: number): Promise<void> {
			this.loading = true;

			try {
				await templatesService.deleteGroup(id);
				await this.fetchGroups();
			} catch (e) {
				notifyApiError(e as AxiosError);
				throw e;
			} finally {
				this.loading = false;
			}
		},
	},
});
