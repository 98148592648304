export const variableNameValidator = (name: string) => !doesIncludeSpecialChars(name) && !doesStartWithNumber(name);

export const doesIncludeSpecialChars = (value: string) => {
	const regex = /[ `!@#$%^&*()+\-=\[\]{};':"\\|,.<>\/?~\s]/;

	return regex.test(value);
};

export const doesStartWithNumber = (value: string) => {
	const regex = /^\d/;

	return regex.test(value);
};
