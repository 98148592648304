<template>
	<q-layout view="hHh lpR fFf">
		<q-header>
			<slot name="header"></slot>
		</q-header>
		<q-page-container class="main">
			<slot name="main"></slot>
		</q-page-container>
		<q-footer elevated class="bg-white text-white">
			<slot name="footer"></slot>
		</q-footer>
	</q-layout>
</template>
