import { NotificationCountByDate } from '@/types/notifications';

export const notificationCountByDateTypes: Array<NotificationCountByDate> = [
	{
		durationLabel: 'Last 6 Months',
		durationType: 'M',
		duration: 6,
		count: 0,
	},
	{
		durationLabel: 'Last 3 Months',
		durationType: 'M',
		duration: 3,
		count: 0,
	},
	{
		durationLabel: 'Last Month',
		durationType: 'M',
		duration: 1,
		count: 0,
	},
	{
		durationLabel: 'Last Week',
		durationType: 'W',
		duration: 1,
		count: 0,
	},
];
