import script from "./CreateVariableDialog.vue?vue&type=script&setup=true&lang=ts"
export * from "./CreateVariableDialog.vue?vue&type=script&setup=true&lang=ts"

const __exports__ = script;

export default __exports__
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QInput from 'quasar/src/components/input/QInput.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QSeparator,QInput});
