import { defineStore } from 'pinia';
import emptyTemplate from '@/constants/CreateTemplateRequest';
import { CreateTemplateRequest } from '@/types/api/request/createTemplateTypes';
import { TemplateCreationStep } from '@/types/enums/enums';

export const useCreateTemplateStore = defineStore({
	id: 'create_template_store',
	state: () => ({
		newTemplate: { ...emptyTemplate } as CreateTemplateRequest,
		step: TemplateCreationStep.CREATE,
	}),
	actions: {
		reset() {
			this.newTemplate = { ...emptyTemplate } as CreateTemplateRequest;
			this.step = TemplateCreationStep.CREATE;
		},
	},
});
