export enum TemplateCreationStep {
	CREATE,
	SETUP,
	SETUP_COMPLETED,
	REVIEW,
	DONE,
}

export enum TemplateStatus {
	DRAFT = 'DRAFT',
	LIVE = 'LIVE',
}
