import '@quasar/extras/material-icons/material-icons.css';
import 'quasar/dist/quasar.css';

import Dialog from 'quasar/src/plugins/Dialog.js';import Loading from 'quasar/src/plugins/Loading.js';import Notify from 'quasar/src/plugins/Notify.js';;

// To be used on app.use(Quasar, { ... })
export default {
	config: {
		brand: {
			primary: '#164e63',
			secondary: '#085ea4',
			accent: '#9C27B0',

			dark: '#1d1d1d',
			'dark-page': '#121212',

			positive: '#21BA45',
			negative: '#C10015',
			info: '#31CCEC',
			warning: '#F2C037',
		},
	},
	plugins: {
		animations: 'all',
		Dialog,
		Notify,
		Loading,
	},
};
