import BaseService from './baseService';

import RequestAuthorizationInterceptor from './interceptors/requestAuthorizationInterceptor';

class UserService extends BaseService {
	constructor() {
		const baseAPIUrl = process.env.VUE_APP_BASE_API_URL;
		super(baseAPIUrl);

		this.http.interceptors.request.use(RequestAuthorizationInterceptor);
	}

	async loginWithKeycloak() {
		return super.get('/user/login-with-keycloak-token');
	}
}

export default new UserService();
