import { AxiosError } from 'axios';
import Notify from 'quasar/src/plugins/Notify.js';;

export const notifyApiError = (error: AxiosError) => {
	const message = (error.response?.data as any).meta?.message ?? 'Unexcpected error occured!';
	Notify.create({
		type: 'negative',
		message,
	});
};

export const notifyUnknownError = (error: unknown) => {
	let message = 'Unexcpected error occured!';

	if (error instanceof Error) {
		message = error.message;
	}

	Notify.create({
		type: 'negative',
		message,
	});
};

export const notifySuccess = (message: string) => {
	Notify.create({
		type: 'positive',
		message,
	});
};

export const notifyWarning = (message: string) => {
	Notify.create({
		type: 'warning',
		message,
	});
};
