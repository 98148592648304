import { interpolatePuBu } from 'd3-scale-chromatic';

/**
 * Generates a color range based on the provided color range info.
 * https://codenebula.io/javascript/frontend/dataviz/2019/04/18/automatically-generate-chart-colors-with-chart-js-d3s-color-scales/
 */
export interface ColorRangeInfo {
	colorStart: number;
	colorEnd: number;
	useEndAsStart: boolean;
}

const defaultColorRange = {
	colorStart: 0,
	colorEnd: 1,
	useEndAsStart: true,
};

const calculatePoint = (i: number, intervalSize: number, colorRangeInfo: ColorRangeInfo) => {
	const { colorStart, colorEnd, useEndAsStart } = colorRangeInfo;

	return useEndAsStart ? colorEnd - i * intervalSize : colorStart + i * intervalSize;
};

export const interpolateColors = (dataLength: number, colorRangeInfo: ColorRangeInfo = defaultColorRange) => {
	const { colorStart, colorEnd } = colorRangeInfo;
	const colorRange = colorEnd - colorStart;
	const intervalSize = colorRange / dataLength;
	const colorArray = [];
	let colorPoint;

	for (let i = 0; i < dataLength; i++) {
		colorPoint = calculatePoint(i, intervalSize, colorRangeInfo);
		colorArray.push(interpolatePuBu(colorPoint));
	}

	return colorArray;
};
