import script from "./TemplateGroupSelector.vue?vue&type=script&setup=true&lang=ts"
export * from "./TemplateGroupSelector.vue?vue&type=script&setup=true&lang=ts"

const __exports__ = script;

export default __exports__
import QSelect from 'quasar/src/components/select/QSelect.js';
import QMenu from 'quasar/src/components/menu/QMenu.js';
import QTree from 'quasar/src/components/tree/QTree.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QSelect,QMenu,QTree,QBtn});
