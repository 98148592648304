import moment from 'moment';

export const formatDateWithDots = (date: Date) => moment(date).format('YYYY.MM.DD');

export const formatDateWithDashes = (date: Date) => moment(date).format('YYYY-MM-DD');

export const formatDateWithSlashes = (date: Date) => moment(date).format('MM/DD/YY');

export const formatDateWithDotsAndTime = (date: Date) => moment(date).format('YYYY.MM.DD h:mm A');

export const formatDateWithDashesAndTime = (date: Date) => moment(date).format('YYYY-MM-DD h:mm A');

export const formatDateWithSlashesAndTime = (date: Date) => moment(date).format('MM/DD/YY h:mm A');
