import { useUserStore } from '@/store/useUserStore';

export default async (error: any) => {
	const { response } = error;
	const { logout } = useUserStore();

	switch (response?.status) {
		case 401:
			logout(true);
			break;
		default:
			break;
	}
};
