import { CreateTemplateGroupRequest, CreateTemplateRequest, CreateVariableRequest } from '@/types/api/request/createTemplateTypes';
import BaseService from './baseService';
import ResponseErrorCheckInterceptor from './interceptors/responseErrorCheckInterceptor';
import RequestAuthorizationInterceptor from './interceptors/requestAuthorizationInterceptor';
import ResponseSuccessCheckInterceptor from '@/api/interceptors/responseSuccessCheckInterceptor';

class TemplatesService extends BaseService {
	constructor() {
		const baseAPIUrl = process.env.VUE_APP_BASE_API_URL;
		super(baseAPIUrl);

		this.http.interceptors.request.use(RequestAuthorizationInterceptor);
		this.http.interceptors.response.use(ResponseSuccessCheckInterceptor, ResponseErrorCheckInterceptor);
	}

	async getTemplates() {
		return this.http.get('/mms/template');
	}

	async getTemplate(id: number) {
		return this.http.get('/mms/template/' + id);
	}

	async deleteTemplate(id: number) {
		return this.http.delete('/mms/template/' + id);
	}

	async getTemplateGroups() {
		return this.get('/mms/template-group');
	}

	async getVariables(params?: unknown) {
		return this.get('/mms/variable', params);
	}

	async createTemplate(data: CreateTemplateRequest) {
		return this.post('/mms/template', data);
	}

	async updateTemplate(template: CreateTemplateRequest) {
		return this.http.put('/mms/template/' + template.id, template);
	}

	async createGroup(data: CreateTemplateGroupRequest) {
		return this.post('/mms/template-group', data);
	}

	async updateGroup(data: CreateTemplateGroupRequest) {
		return this.put('/mms/template-group/' + data.id, data);
	}

	async deleteGroup(id: number) {
		return this.delete('/mms/template-group/' + id);
	}

	async createVariable(data: CreateVariableRequest) {
		return this.post('/mms/variable', data);
	}

	async deleteVariable(templateId: number) {
		return this.delete('/mms/variable/' + templateId);
	}
}

export default new TemplatesService();
