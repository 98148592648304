import { NotificationCountFilterParam, NotificationPerGroupFilterParam } from '@/types/api/params/notificationDateFilter';
import { Maybe } from '@/types/coretypes';
import BaseService from './baseService';
import RequestAuthorizationInterceptor from './interceptors/requestAuthorizationInterceptor';
import ResponseErrorCheckInterceptor from './interceptors/responseErrorCheckInterceptor';
import ResponseSuccessCheckInterceptor from '@/api/interceptors/responseSuccessCheckInterceptor';

class NotificationsService extends BaseService {
	constructor() {
		const baseAPIUrl = process.env.VUE_APP_BASE_API_URL;
		super(baseAPIUrl);

		this.http.interceptors.request.use(RequestAuthorizationInterceptor);
		this.http.interceptors.response.use(ResponseSuccessCheckInterceptor, ResponseErrorCheckInterceptor);
	}

	async getNotificationsPerGroup(params?: Maybe<NotificationPerGroupFilterParam>) {
		return this.get('/mms/notification/count-per-template-group', params);
	}

	async getNotificationCountsByDate(params?: Maybe<NotificationCountFilterParam>) {
		return this.get('/mms/notification/count', params);
	}
}

export default new NotificationsService();
