import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { Maybe } from '@/types/coretypes';

export default class BaseService {
	protected http: AxiosInstance;

	constructor(baseURL: string) {
		this.http = axios.create({ baseURL });
	}

	protected async get(url: string, params?: Maybe<unknown>) {
		return this.http.get(url, { params });
	}

	protected async post(url: string, data?: Maybe<unknown>, config?: Maybe<AxiosRequestConfig>) {
		return this.http.post(url, data, config);
	}

	protected async put(url: string, data?: Maybe<unknown>, config?: Maybe<AxiosRequestConfig>) {
		return this.http.put(url, data, config);
	}

	protected async patch(url: string, data?: Maybe<unknown>) {
		return this.http.patch(url, data);
	}

	protected async delete(url: string, config?: AxiosRequestConfig) {
		return this.http.delete(url, config);
	}
}
