<template>
	<q-markup-table flat dense bordered class="full-height">
		<thead>
			<tr>
				<th class="text-left">
					<q-skeleton animation="blink" type="text" />
				</th>
				<th class="text-left">
					<q-skeleton animation="blink" type="text" />
				</th>
				<th class="text-left">
					<q-skeleton animation="blink" type="text" />
				</th>
				<th class="text-left">
					<q-skeleton animation="blink" type="text" />
				</th>
				<th class="text-left">
					<q-skeleton animation="blink" type="text" />
				</th>
				<th class="text-left">
					<q-skeleton animation="blink" type="text" />
				</th>
				<th class="text-left">
					<q-skeleton animation="blink" type="text" />
				</th>
			</tr>
			<tr></tr>
		</thead>

		<tbody>
			<tr v-for="n in 17" :key="n">
				<td class="text-left">
					<q-skeleton animation="blink" type="text" />
				</td>
				<td class="text-right">
					<q-skeleton animation="blink" type="text" />
				</td>
				<td class="text-right">
					<q-skeleton animation="blink" type="text" />
				</td>
				<td class="text-right">
					<q-skeleton animation="blink" type="text" />
				</td>
				<td class="text-right">
					<q-skeleton animation="blink" type="text" />
				</td>
				<td class="text-right">
					<q-skeleton animation="blink" type="text" />
				</td>
				<td class="text-right">
					<q-skeleton animation="blink" type="text" />
				</td>
			</tr>
		</tbody>
	</q-markup-table>
</template>
